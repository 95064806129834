import styles from "./Logo.module.scss";
import { event } from "nextjs-google-analytics";
interface LogoProps {
  link: string;
  size?: string;
}

const Icon: React.FC<LogoProps> = ({ link, size }) => {
  const handleGoogle = () => {
    event(`btn-instagram`, {
      category: "CTA",
    });
    const win = window.open(link, "_blank");
    win?.focus();
  };
  return (
    <div
      onClick={handleGoogle}
      className={`${styles.logo_social} ${styles[size ? size : ""]}`}
    >
      <svg
        width="20"
        height="28"
        viewBox="0 0 20 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.0003 27.5C9.31026 27.5 8.68026 27.11 8.32026 26.495L3.52026 18.02C3.26526 17.555 3.01026 17.09 2.78526 16.61C1.16526 13.085 -0.544739 7.88 3.43026 3.47C5.17026 1.565 7.49526 0.5 10.0003 0.5C12.5053 0.5 14.8303 1.565 16.5703 3.485C20.5753 7.91 18.8353 13.16 17.1703 16.715C16.9753 17.135 16.7653 17.54 16.5403 17.93L11.6803 26.495C11.3353 27.11 10.6903 27.5 10.0003 27.5ZM10.0003 2C7.93026 2 5.99526 2.885 4.54026 4.49C1.15026 8.24 2.69526 12.845 4.13526 15.995C4.34526 16.43 4.57026 16.865 4.81026 17.285L9.61026 25.76C9.79026 26.075 10.1803 26.075 10.3603 25.76L15.2203 17.195C15.4303 16.835 15.6253 16.46 15.7903 16.085C17.2753 12.905 18.8353 8.255 15.4303 4.49C14.0053 2.885 12.0703 2 10.0003 2Z" />
        <path d="M10 14C7.525 14 5.5 11.975 5.5 9.5C5.5 7.025 7.525 5 10 5C12.475 5 14.5 7.025 14.5 9.5C14.5 11.975 12.475 14 10 14ZM10 6.5C8.35 6.5 7 7.85 7 9.5C7 11.15 8.35 12.5 10 12.5C11.65 12.5 13 11.15 13 9.5C13 7.85 11.65 6.5 10 6.5Z" />
      </svg>
    </div>
  );
};

export default Icon;
