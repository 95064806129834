import Image from "next/image";
import FB from "@/components/Logo/FB";
import Insta from "@/components/Logo/Insta";
import YT from "@/components/Logo/YT";
import styles from "./Footer.module.scss";
import Link from "next/link";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.tira} />
      <div className={styles.footer_row}>
        <div className={styles.footer_items_sociales}>
          <h3>Seguinos en las redes</h3>
          <ul className={styles.items_social}>
            <li>
              {" "}
              <FB link="https://www.facebook.com/profile.php?id=61551652981975" size={'footer'} />
            </li>
            <li>
              {" "}
              <Insta link="https://www.instagram.com/facehaus.ar/" size={'footer'} />
            </li>
            {/* <li>
              {" "}
              <YT link="https://www.google.com/" size={'footer'} />
            </li> */}
          </ul>
        </div>
      </div>
      <div className={styles.footer_btn}>
        <a  href={`${process.env.base}/politica-privacidad`} target="_blank" className={styles.footer_btn_item}>Legales</a>
        
      </div>
      <div className={styles.footer_line} />
      <div className={styles.footer_desktop}>
        <div>
          <a href={`${process.env.base}/politica-privacidad`} target="_blank" >Legales</a>
          {/* <a href={`${process.env.base}/terminos-condiciones`} target="_blank" >Términos y condiciones</a> */}
          
        </div>
        <span>© 2023, Face Haus</span>
      </div>
      <span className={styles.footer_btn}>© 2023, Face Haus</span>
    </footer>
  );
};
export default Footer;
