import styles from "./Banner.module.scss";
import Image from "next/image";
import carpeta from "../../assets/images/carpeta.svg";
import women from "../../assets/images/women-banner.svg";
import shield from "../../assets/images/shield.svg";
import { motion } from "framer-motion";
interface BannerProps {}
const childVariants = {
  initial: {
    y: 50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 1,
    },
  },
};
const line = {
    initial: {
        x: '100%',
      },
      animate: {
        x: 0,
        transition: {
          ease: [0.6, 0.01, 0.05, 0.95],
          delay: 0.5,
          duration: 1,
        },
      },
}

const container = {
  //   initial: {
  //     y: "-100%",
  //   },
  animate: {
    // y: 0,
    transition: {
      style: "spring",
      //   delay: 1.2,
      duration: 1,
      delayChildren: 0,
      staggerChildren: 0.3,
    },
  },
};

const list = [
  {
    text: "Asesoramiento integral",
    ico: shield,
  },
  {
    text: "Propuestas innovadoras",
    ico: women,
  },
  {
    text: "Equipo de expertos",
    ico: carpeta,
  },
];
const Banner: React.FC<BannerProps> = () => {
  return (
    <motion.section
      className={styles.banner}
      variants={container}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true, margin: "-10px 0px" }}
      //   viewport={container}
    >
      <motion.div variants={line} className={styles.banner_line} />
      {list.map((item, index) => (
        <motion.article
          variants={childVariants}
          key={index}
          className={styles.banner_item}
        >
          <Image src={item.ico} alt={item.text} width={64} height={64} />{" "}
          <p>{item.text}</p>
        </motion.article>
      ))}
      
    </motion.section>
  );
};

export default Banner;
