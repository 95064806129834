import Image from "next/image";
import quotes from "@/assets/images/quotes.svg";
import { useState, useEffect, useRef } from "react";
import styles from "./Testimonie.module.scss";
import { motion } from "framer-motion";
import { getCurrentHub } from "@sentry/nextjs";

interface TestimoniesProps {
  list?: any[];
}
const childVariants = {
  initial: {
    y: 50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: "easeOut",
      duration: 0.5,
    },
  },
};
const container = {
  initial: {
    x: 0
  },
  animate: {
    transition: {
      style: "spring",
      duration: 1,
      delayChildren: 0,
      staggerChildren: 0.3,
    },
  },
};

const Testimonie: React.FC<TestimoniesProps> = ({ list = [] }) => {
  const [lista, setList] = useState<any[]>([]);
  const containerRef = useRef<any>(null);
  const [isDragging, setIsDragging] = useState<any>(false);
  const [mouseDownX, setMouseDownX] = useState<any>(null);
  const [scrollContainer, setScrollLeft] = useState<any>(0);
  const [startX, setStartX] = useState<any>(null);

  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    setStartX(e.pageX);
    // setMouseDownX(e.pageX - containerRef.current.offsetLeft);
  };

  const handleMouseMove = (e: any) => {
    e.preventDefault();
    if (isDragging) {
      setScrollLeft(false)
      const x = ((e.pageX - startX) * 0.5) / list.length;
      containerRef.current.scrollLeft = containerRef.current.scrollLeft - x;
      if(containerRef.current.scrollLeft <= 0){
        setScrollLeft(true)
      }else if(containerRef.current.scrollLeft + containerRef.current.offsetWidth >= containerRef.current.scrollWidth){
        setScrollLeft(true)
      }
      scrollTo({
        behavior: "smooth",
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setMouseDownX(null);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    setMouseDownX(null);
  };
  useEffect(() => {
    setList(list);
  }, [list]);
  // useEffect(() => {
  //   let animationFrameId: any;

  //   function handleAnimationFrame() {
  //     if (!isDragging) {
  //       setScrollLeft((prevScrollLeft: any) => {
  //         const distance = startX - containerRef.current.offsetLeft;
  //         return prevScrollLeft + distance * 0.06; // Modifica aquí la velocidad del scroll
  //       });
  //     }

  //     animationFrameId = requestAnimationFrame(handleAnimationFrame);
  //   }

  //   animationFrameId = requestAnimationFrame(handleAnimationFrame);

  //   return () => {
  //     cancelAnimationFrame(animationFrameId);
  //   };
  // }, [isDragging, startX]);
  return (
    <motion.div
      ref={containerRef}
      variants={container}
      whileInView={"animate"}
      initial="initial"
      viewport={{ once: true, margin: "-150px 0px" }}
      className={styles.testimonie_container}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      drag={ scrollContainer ? 'x': false}
  dragConstraints={{ left: 0, right: 0  }}
  dragElastic={true}
    >
      {/* <div className={styles.cards}> */}
      {lista.length > 0
        ? lista.map((test, i) => (
            <motion.article
              variants={childVariants}
              key={`testi-${test.id}-${i}`}
              className={styles.testimonie}
            >
              <div className={styles.testimonie_header}>
                <div className={styles.testimonie_header_title}>
                  <h4>{test.name}</h4>
                  <span>{test.location}</span>
                </div>
                <figure>
                  <Image src={quotes} alt="image" fill={true} />
                </figure>
              </div>
              <p
                className={styles.testimonie_description}
                dangerouslySetInnerHTML={{ __html: test.content }}
              />
            </motion.article>
          ))
        : null}
      {/* </div> */}
    </motion.div>
  );
};

export default Testimonie;
