import { useState, useEffect, useRef } from "react";
import Head from "next/head";
import Image from "next/image";
import facial1 from "@/assets/images/armonizacion.jpg";
import facial2 from "@/assets/images/facial-2.svg";
import facial3 from "@/assets/images/facial-3.svg";
import facial4 from "@/assets/images/facial-4.svg";
import pin from "@/assets/images/pin.svg";
import map from "../assets/images/maps.jpg";
import mapD from "@/assets/images/maps.jpg";
import phone from "@/assets/images/phone.svg";
import styles from "@/styles/Home.module.scss";
import Slider from "@/components/Slider";
import Banner from "@/components/Banner";
import Lettering from "@/components/Lettering";
import Button from "@/components/Button";
import Hero from "@/components/Hero";
import CardRow from "@/components/Card";
import Testimonie from "@/components/Card/Testimonie";
import Pin from "@/components/Logo/Pin";
import { GetServerSideProps } from "next/types";
import axios from "axios";
import { motion, useTransform, useMotionValue } from "framer-motion";
import { mounted } from "@/utils/scrollbar";
import { isMobile, isTablet } from "react-device-detect";
import Footer from "@/components/Footer";

// --------------------------------- CHILD VARIANTS ------------------------------------//

const childVariants = {
  initial: {
    y: 50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 1,
    },
  },
};
// --------------------------------- CONTAINER VARIANTS ---------------------------------------//
const cardContainer = {
  animate: {
    transition: {
      style: "spring",
      duration: 1,
      delayChildren: 0.5,
      staggerChildren: 1.5,
    },
  },
};
const container = {
  animate: {
    transition: {
      style: "spring",
      delay: 0.8,
      duration: 1,
      delayChildren: 0,
      staggerChildren: 0.3,
    },
  },
};
export default function Home({ home }: any) {
  const [mobile, setMobile] = useState<null | boolean>(null);
  const [innserScroll, setInner] = useState<number | null>(null);
  const containerRef = useRef<any>(null);
  const scrollY = useMotionValue(0);
  const slowScrollY = useTransform(
    scrollY,
    [0, containerRef.current?.scrollHeight || 0],
    [0, 2000]
  );
  const device = {
    isMobile,
    isTablet,
    isDesktop: !isMobile && !isTablet,
  };
  useEffect(() => {
    window.innerWidth < 600 ? setMobile(true) : setMobile(false);
    window.addEventListener("resize", () => {
      window.innerWidth < 600 ? setMobile(true) : setMobile(false);
    });

    setInner(containerRef.current.scrollHeight);
    mounted(device, containerRef.current);
    // const handleScroll = () => {
    //   console.log(containerRef.current.scrollHeight)
    //   scrollY.set(containerRef.current.scrollTop);
    // };

    // window.addEventListener('scroll', handleScroll);

    // return () => {
    //   window.removeEventListener('scroll', handleScroll);
    // };
    const handleScroll = () => {
      const { top } = containerRef.current.getBoundingClientRect();
      scrollY.set(-top);
    };

    handleScroll(); // Actualizar el valor de scrollY al cargar la página

    const handleResize = () => {
      handleScroll(); // Actualizar el valor de scrollY al cambiar el tamaño de la ventana
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef]);
  return (
    <>
      <Head>
        <title>Face Haus</title>
        <meta
          name="description"
          content="Face Haus es el primer centro de estética en Argentina especializado en el cuidado facial de hombres y mujeres de todas las edades."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div ref={containerRef} style={{ height: "100vh" }} id="main_home">
        <main className={styles.main_home}>
          {/* <motion.div style={{ y: slowScrollY }}> */}
          <section className={styles.home_slider_container}>
            <Slider list={home.home_banners} />
          </section>
          <Banner />

          <motion.div
            variants={container}
            initial={"initial"}
            whileInView={"animate"}
            viewport={{
              once: true,
              margin: `${mobile ? "-300px" : "-300px"} 0px`,
            }}
            className={styles.main_greet}
          >
            <motion.h1 variants={childVariants}>
              ¡Te damos la bienvenida a{" "}
              <span className={styles.main_greet_logo}>Face Haus!</span>
            </motion.h1>
            <motion.p variants={childVariants}>
              Somos el{" "}
              <strong>
                primer centro médico del país especializado en rostros
              </strong>{" "}
              que trae al mercado una propuesta innovadora de la mano de{" "}
              <strong>
                expertos en procedimientos y tratamientos faciales no-invasivos
              </strong>
              , mejorando la calidad de vida de sus pacientes y logrando un
              equilibrio entre la estética y la salud.
            </motion.p>
            <motion.div
              variants={childVariants}
              className={styles.main_greet_btn}
            >
              <Button
                filled={false}
                text={"seguir leyendo"}
                size="large"
                classes="right"
                link={"/quienes-somos"}
              />
            </motion.div>
          </motion.div>

          <Hero />

          <motion.section
            variants={cardContainer}
            initial={"initial"}
            whileInView={"animate"}
            viewport={{ once: true, margin: "-50px 0px" }}
            className={styles.main_card_row}
          >
            <motion.h1 variants={childVariants}>
              En Face Haus tenemos los tratamientos que{" "}
              <motion.span variants={childVariants}>
                tu rostro necesita
              </motion.span>
            </motion.h1>
            <CardRow list={home.highlight_treatments} mobile={mobile} />
            <motion.div variants={childVariants}>
              <Button
                filled={true}
                text={"VER TODOS"}
                size="medium-mobile"
                link={"/tratamientos"}
              />
            </motion.div>
          </motion.section>

          <motion.section
            variants={container}
            initial={"initial"}
            whileInView={"animate"}
            viewport={{ once: true, margin: "-50px 0px" }}
            className={styles.facial}
          >
            {/* <motion.p
            // variants={childVariants}
            className={styles.facial_banner_lettering}
          >
            armonización facial
          </motion.p> */}
            <Lettering text="armonización facial" />
            <motion.div
              variants={container}
              initial={"initial"}
              whileInView={"animate"}
              viewport={{ once: true, margin: "-100px 0px" }}
              className={styles.facial_banner}
            >
              <motion.figure variants={childVariants}>
                <Image src={facial1} alt="1" width={340} />
              </motion.figure>
              {/* <motion.figure variants={childVariants}>
              <Image src={facial2} alt="1" fill />
            </motion.figure>
            <motion.figure variants={childVariants}>
              <Image src={facial3} alt="1" fill />
            </motion.figure>
            <motion.figure variants={childVariants}>
              <Image src={facial4} alt="1" fill />
            </motion.figure> */}
            </motion.div>
            <motion.article
              variants={container}
              initial={"initial"}
              whileInView={"animate"}
              viewport={{ once: true, margin: "-50px 0px" }}
              className={styles.facial_text}
            >
              <motion.h3 variants={childVariants}>
                Armonización facial
              </motion.h3>
              <motion.p variants={childVariants}>
                Una técnica pensada para destacar la belleza natural de cada
                persona, mejorando cada detalle del rostro. Combinando nuestros
                tratamientos, podés conseguir resultados increíbles realzando
                los puntos favorables de tus rasgos faciales y definiendo los
                ángulos del mismo.
              </motion.p>
              <motion.div variants={childVariants}>
                <Button
                  filled={false}
                  text={"mirá los tratamientos"}
                  size={"xl"}
                  link={"/preocupacion/lograr-la-armonizacion-facial"}
                />
              </motion.div>
            </motion.article>
          </motion.section>
          {home.testimonials.length > 0 && (
            <section className={styles.testimonies_home}>
              <motion.article
                variants={container}
                initial={"initial"}
                whileInView={"animate"}
                viewport={{ once: true, margin: "-50px 0px" }}
                className={styles.testimonies_home_text}
              >
                <motion.h3 variants={childVariants}>
                  Por qué nos eligen
                </motion.h3>
                <motion.p variants={childVariants}>
                  Mirá lo que nuestros pacientes tienen para decir sobre Face
                  Haus
                </motion.p>
              </motion.article>
              <Testimonie list={home.testimonials} />
            </section>
          )}

          <motion.section
            variants={container}
            initial={"initial"}
            whileInView={"animate"}
            viewport={{ once: true, margin: "-100px 0px" }}
            className={styles.date}
          >
            <motion.article
              variants={container}
              initial={"initial"}
              whileInView={"animate"}
              viewport={{ once: true, margin: "0px 0px" }}
              className={styles.date_title}
            >
              <motion.h1
                variants={childVariants}
                className={styles.date_title_top}
              >
                ¿No sabes por dónde empezar?
              </motion.h1>
              <motion.h1 variants={childVariants}>
                ¡Agendá tu consulta, y obtené el{" "}
                <span>primer diagnóstico bonificado! </span>
              </motion.h1>
            </motion.article>
            <motion.p variants={childVariants}>
              Uno de nuestros especialistas hará una revisión general de tu
              rostro y evaluará los mejores tratamientos para ti en base a tus
              necesidades.
              {/* tratamiento para vos. */}
            </motion.p>
            <motion.div variants={childVariants}>
              <Button
                filled={true}
                text={"AGENDAR TURNO"}
                size={"medium"}
                module="btn-home"
                link={"https://facehaus.site.agendapro.com/ar"}
                cta={true}
              />
            </motion.div>
          </motion.section>
          <div className={styles.date_map}>
            <figure className={styles.date_map_mobile}>
              <Image
                src={`${mobile ? "/images/mapmobile.jpg" : "/images/maps.jpg"}`}
                alt="map"
                fill={true}
              />
            </figure>
            <section>
              <div className={styles.text}>
                <h3>¡Hablemos!</h3>
                <p>
                  Es importante para nosotros que te sientas a gusto, que estés
                  conforme con nuestra atención y con los resultados de nuestros
                  tratamientos. No dudes en contactarnos ante cualquier
                  inquietud.
                </p>
              </div>
              <div className={styles.text}>
                <h3>Vení a vernos</h3>
                <div className={styles.text_row}>
                  <div className={styles.text_row_info}>
                    <a
                      href="https://goo.gl/maps/XHNBChyeydaSsZKy5"
                      target="_blank"
                    >
                      <div className={styles.map_pin}>
                        <Pin link="" />
                      </div>
                      <p>
                        <span>Gorriti 4919 PB</span>
                        <span>Palermo C1414BJO</span>
                      </p>
                    </a>
                  </div>
                  <div className={styles.text_row_info}>
                    <Image
                      src={phone}
                      alt="point"
                      width={20}
                      height={30}
                      className={styles.map_pin}
                    />
                    <p>
                      <span>15-6052-4919 | 5242-4919</span>
                      <span>Buenos Aires</span>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* </motion.div>   */}
          <Footer />
        </main>
      </div>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (cxt: any) => {
  const res = await axios.get(`${process.env.api}/home-data`);
  // const venta = window.innerWidth;
  const data: any = res.data;

  return {
    props: {
      home: data.data,
    },
  };
};
