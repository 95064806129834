import {useState, useEffect, useRef} from 'react'
import styles from "./Card.module.scss";
import { stopScroll } from "@/utils/scrollbar";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import Image from 'next/image'
interface Card {
  image: string;
  title: string;
  description: string;
  slug: string;
}
interface CardProps {
  list: any[];
  mobile: any
}
const childVariants = {
  initial: {
    y: 50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: "easeOut",
      duration: 0.5,
    },
  },
};
const cardVariants = {
  initial: {
    y: 150,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 2,
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
};

const container = {
  animate: {
    transition: {
      style: "spring",
      duration: 1,
      delayChildren: 0,
      staggerChildren: 0.3,
    },
  },
};

const Card: React.FC<CardProps> = ({ list, mobile }) => {
  const [ move, setMove]= useState<number>(0);
  const time = useRef<any>(null)

  function scrollMove(e: any){
    console.log(e.targetTouches[0].clientX)
    time.current = setTimeout( () => {
      if(e.targetTouches[0].clientX > (move + 20) || e.targetTouches[0].clientX < (move - 20)){
        stopScroll(true, null)
      }
      setMove(e.targetTouches[0].clientX)  
    },100)
    
  }
  function scrollEnd(e: any){
    clearTimeout(time.current)
    setTimeout(() =>{stopScroll(false,null)},150)
    
    console.log(e)

  }
  const router = useRouter();
  return (
    <motion.section
      variants={container}
      whileInView={"animate"}
      initial={"initial"}
      viewport={{ once: true, margin: "-100px 0px" }}
      className={`${styles.card_container} ${styles[`_${list.length}`]}`}
    >
      {list.length > 0 &&
        list.map((card, i) => (
          <motion.article
            variants={cardVariants}
            key={i}
            className={styles.card}
            onClick={() => router.push(`/tratamiento/${card.slug}`, undefined)}
            onTouchMove={(e)=> scrollMove(e)}
            onTouchEnd={(e) => scrollEnd(e)}
          >
            <motion.div
              variants={childVariants}
              className={styles.card_thumb_container}
            >
              <figure className={styles.card_thumb}>
                <Image
                  src={card.highlight_thumbnail}
                  className={styles.img}
                  alt=""
                  fill
                />
              </figure>
            </motion.div>
            <motion.h3 variants={childVariants}>
              {card.highlight_title}
            </motion.h3>
            <motion.p variants={childVariants}>
              {card.highlight_subtitle}
            </motion.p>
            <motion.div variants={childVariants}>
              <div className={styles.card_btn} />
            </motion.div>
          </motion.article>
        ))}
    </motion.section>
  );
};

export default Card;
