import styles from "./Slider.module.scss";
import Button from "../Button/index";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Image from "next/image";
declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void;
  }
}

interface SliderProps {
  list: any[];
}
// --------------------------------- CONTAINER VARIANTS --------------------------//
const dotsContainer = {
  initial: { opacity: 0, y: 50 },
  animate: {
    opacity: 1,
    y: 0,

    transition: {
      y: {
        duration: 1,
      },
      duration: 2,
      delay: 1.5,
      delayChildren: 1,
      staggerChildren: 0.1,
    },
  },
};
const containerVariants = {
  initial: (flag: any) => {
    return {
      opacity: 0.3,
      x: flag.side === true ? "5vw" : "-5vw",
    };
  },
  animate: (flag: any) => {
    return {
      opacity: 1,
      x: 0,
      transition: {
        opacity: {
          delay: flag.flag === true ? 0.5 : 0,
          duration: 1.5,
        },
        x: {
          duration: 1.5,
        },
        // delay: flag.flag === true ? 8: 0,
        ease: [0.6, 0.81, 0.35, 0.95],
        delayChildren: 0.5,
        staggerChildren: 0.3,
      },
    };
  },
  exit: (flag: any) => {
    return {
      opacity: 0,
      x: flag.side === true ? "-5vw" : "5vw",
      scale: 1,
      // when: "never",
      transition: {
        delay: 0,
        duration: 1,
        ease: "easeOut",
        delayChildren: 0,
        staggerChildren: 0,
      },
    };
  },
};
const boxVariants = {
  initial: (flag: any) => {
    return {
      opacity: flag.flag === true ? 1 : 0,
    };
  },
  animate: (flag: any) => {
    return {
      opacity: 1,
      transition: {
        duration: 0.1,
        delay: 0,
        delayChildren: 0,
        staggerChildren: 0.3,
      },
    };
  },
};

// ------------------------------ CHILD VARIANTS -------------------------------//
const childVariants = {
  initial: {
    y: 50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    transition: {
      delay: 0,
      duration: 0.1,
    },
  },
};

const Slider: React.FC<SliderProps> = ({ list }) => {
  const [counter, setCount] = useState(0);
  const [flag, setFlag] = useState<any>({
    flag: false,
    side: true,
    initial: false,
  });
  const [animation, setAnimate] = useState<boolean>(false);
  const [mobile, setMobile] = useState<boolean | null>(null);
  const [startX, setStartX] = useState<any>(null);
  const [endX, setEndX] = useState<any>(null);
  const [deltaX, setDeltaX] = useState<any>(null);
  const [timer, setTimer] = useState<any>(null);
  const controls = useAnimation();
  const configurations = {
    youtube: {
      playerVars: {
        disablekb: 1,
        autoplay: 1,
        mute: 1,
      },
    },
  };

  useEffect(() => {
    const ventana = window.innerWidth > 600;
    setMobile(ventana);
    const adjust = () => {
      const ventana = window.innerWidth > 600;
      setMobile(ventana);
    };
    window.addEventListener("resize", adjust);
    controls.start("animate");
    setAnimate(true);
    return () => window.removeEventListener("resize", adjust);
  }, []);

  useEffect(() => {
    clearTimeout(timer);
    if (animation) controls.start("animate");
    return () => {
      clearTimeout(timer);
    };
  }, [counter]);

  const sliderCount = (e: any) => {
    controls.stop();
    clearTimeout(timer);
    if (e === "animate") {
      // controls.stop();
      // if(!flag.flag) {
      const time = setTimeout(() => {
        counter + 1 < list.length ? setCount(counter + 1) : setCount(0);
        setAnimate(true);
      }, 10000);

      setTimer(time);
      setFlag({ ...flag, side: true, flag: true });

      setFlag({ ...flag, side: true });
    }
  };

  const sliderChange = (c: any) => {
    clearTimeout(timer);
    setAnimate(false);
    controls.stop();
    setCount(c);
  };

  const goNext = (n: number) => {
    clearTimeout(timer);
    controls.stop();
    setAnimate(false);
    const newCount =
      counter + n < 0
        ? list.length - 1
        : counter + n > list.length - 1
        ? 0
        : counter + n;

    if (n < 0) {
      setFlag({ ...flag, side: false });
    } else {
      setFlag({ ...flag, side: true });
    }
    setCount(newCount);
  };
  const handleSwipe = (end: any) => {
    if (startX - end > 100) {
      goNext(1);
    } else if (end - startX > 100) {
      goNext(-1);
    }
    setStartX(null);
    setEndX(null);
  };
  const handleTouchStart = (event: any) => {
    setStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event: any) => {
    if (startX) setDeltaX(event.touches[0].clientX - startX);
  };

  const handleTouchEnd = (event: any) => {
    setEndX(event.changedTouches[0].clientX);
    handleSwipe(event.changedTouches[0].clientX);
  };
  return (
    <div className={styles.slider_container}>
      <AnimatePresence initial={true} custom={flag}>
        <motion.article
          onAnimationComplete={(e) => sliderCount(e)}
          variants={containerVariants}
          initial="initial"
          animate={"animate"}
          exit={"exit"}
          custom={flag}
          key={counter}
          className={`${styles.slide_item} }`}
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchEnd={(e) => handleTouchEnd(e)}
          onTouchMove={(e) => handleTouchMove(e)}
        >
          <motion.div
            className={`${styles.back} ${
              list[counter].title ? "" : styles.open
            }`}
          >
            {list[counter].background_video_url && mobile ? (
              <ReactPlayer
                playing={true}
                loop={true}
                controls={false}
                muted={true}
                width={ mobile ? "100%" : "150%"}
                height={mobile ? "105%" : "35%"}
                url={list[counter].background_video_url}
                config={configurations}
              />
            ) : (
              <motion.div>
                <Image src={`${
                  mobile
                    ? list[counter].desktop_image
                    : list[counter].mobile_image
                }`}
                alt="" 
                loading="eager"
                fill
                />
              {/* <motion.img
                src={`${
                  mobile
                    ? list[counter].desktop_image
                    : list[counter].mobile_image
                }`}
                alt=""
              /> */}
              </motion.div>
            )}
          </motion.div>
          {list[counter].title ? (
            <motion.div
              variants={boxVariants}
              custom={flag}
              className={styles.container_text}
            >
              <motion.span
                variants={childVariants}
                style={{
                  color: !mobile ? null : list[counter].upper_text_color,
                }}
              >
                {list[counter].upper_text}
              </motion.span>
              <motion.p
                variants={childVariants}
                style={{ color: !mobile ? null : list[counter].title_color }}
              >
                {list[counter].title}
              </motion.p>
              <motion.span
                variants={childVariants}
                style={{ color: !mobile ? null : list[counter].subtitle_color }}
                className={styles.container_text_lower}
              >
                {" "}
                {list[counter].subtitle}
              </motion.span>
              <motion.div
                variants={childVariants}
                className={styles.slider_btn}
              >
                {list[counter].button_url ? (
                  <Button
                    filled={true}
                    text={list[counter].button_text}
                    size={"large"}
                    color={list[counter].button_text_color}
                    link={list[counter].button_url}
                    module={list[counter].title}
                    cta={true}
                  />
                ) : null}
              </motion.div>
            </motion.div>
          ) : null}
        </motion.article>
      </AnimatePresence>
      <motion.div
        variants={dotsContainer}
        initial="initial"
        animate="animate"
        className={styles.slider_dots}
      >
        {" "}
        {list.map((l, i) => (
          <motion.div key={i + l.title} variants={childVariants}>
            <span
              className={`${styles.slider_dots_dot} ${
                i === counter ? styles.slider_dots_dot_active : ""
              }`}
              onClick={() => sliderChange(i)}
            />
          </motion.div>
        ))}{" "}
      </motion.div>
    </div>
  );
};

export default Slider;
