import styles from "./Hero.module.scss";
import first from "@/assets/images/h02.jpg";
import second from "@/assets/images/h01.jpg";
import third from "@/assets/images/h03.jpg";
import Image from "next/image";
import { motion } from "framer-motion";

interface HeroProps {}
const childVariants = {
  initial: {
    y: 50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 0.5,
      y: {
        duration: 1,
        // delay: 1.2
      },
      staggerChildren: 0.2,
    },
  },
};

const container = {
//   initial: {
//     y: 0,
//     opacity: 0,
//   },
  animate: {
    // y: 0,
    // opacity: 1,
    transition: {
      style: "spring",
      delay: 0.2,
      duration: 1,
      delayChildren: 0.2,
      staggerChildren: 1,
    },
  },
};
const Hero: React.FC<HeroProps> = () => {
  return (
    <motion.section
      variants={container}
      initial="initial"
      whileInView={"animate"}
      viewport={{once: true,  margin: "-25% 0px" }}
      className={styles.hero_container}
    >
      <motion.div variants={childVariants} className={styles.hero_left}>
        <motion.div variants={childVariants} className={styles.hero_left_top}>
          <Image src={first} alt="image" fill={true} />
        </motion.div>
        <motion.div
          variants={childVariants}
          className={styles.hero_left_bottom}
        >
          <Image src={second} alt="image" fill={true} />
        </motion.div>
      </motion.div>
      <motion.div variants={childVariants} className={styles.hero_right}>
        <motion.article className={styles.hero_right_text}>
          <motion.span variants={childVariants} className={styles.upper}>
            Nuestra <strong>Haus</strong>
          </motion.span>
          <motion.p variants={childVariants}>Un lugar pensado para vos</motion.p>
          <motion.span variants={childVariants} className={styles.hero_right_text_color}>
          ¡Buscamos siempre tu comodidad!
          </motion.span>
        </motion.article>
        <motion.div variants={childVariants} className={styles.hero_right_img}>
          <Image src={third} alt="image" fill={true} />
        </motion.div>
      </motion.div>
    </motion.section>
  );
};
export default Hero;
