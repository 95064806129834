import { useState } from "react";
import styles from "./Button.module.scss";
import arrow from "../../assets/images/arrow-inverted.svg";
import arrowHover from "../../assets/images/arrowHover.svg";
import arrowBlue from "@/assets/images/arrow-blue.svg";
import Image from "next/image";
import Link from "next/link";
import { Url } from "next/dist/shared/lib/router/router";
import { event } from "nextjs-google-analytics";
interface ButtonProps {
  filled: boolean;
  text: string;
  size: string;
  color?: string;
  link?: Url;
  classes?: string | null;
  module?: string,
  cta?: boolean
}
const Button: React.FC<ButtonProps> = ({
  filled,
  text,
  size,
  color,
  link,
  classes,
  module,
  cta = false
}) => {
  const [current, setCurrent] = useState(false);
  const handleGoogle = () => {
    module
    ?
    event(`${module}-module`, {
      category:'CTA'
    })
    : null
  }
  return (
    <div
      className={` ${styles.btn} ${filled ? styles.filled : styles.notfilled} ${
        styles[size]
      } ${color ? styles[color] : ""} ${styles[classes ? classes : ""]}`}
      onMouseOver={() => setCurrent(true)}
      onMouseOut={() => setCurrent(false)}
    >
      <Link href={`${link}`} onClick={handleGoogle} target={cta ? '_blank' : ''}>
        {" "}
       {text}
        <Image
          src={
            size === "xl" ? arrowBlue : current && filled ? arrowHover : arrow
          }
          alt="arrow"
          width={63}
          height={10}
        />
      </Link>
    </div>
  );
};

export default Button;
